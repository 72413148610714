import React from "react"
import BookList from "../components/book/list"
import Layout from "../components/layout"

import SEO from "../components/seo"

export default function BookPage() {
  return (
    <Layout noStyles>
      <SEO title="Books" />

      <div className="min-vh-100 mx-auto max-width-1100px">
        <BookList />
      </div>
    </Layout>
  )
}
